import React, { useEffect, useMemo, useState } from 'react';
import { theme } from '@redislabsdev/redis-ui-styles';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { Button, DatePicker } from '@redislabsdev/redislabs-ui-components';
import { Input } from '@redislabsdev/redis-ui-components';
import { CancelIcon, CalendarIcon, InfoIcon } from '@redislabsdev/redis-ui-icons';

import * as CS from '../../../../styles/common.style';

import { errorsMessages } from '../../../../utils/errorMessages';
import { updateStateOnInputType, utcDateStartOfDay } from '../../../../utils/common.utils';
import { StoreInterface } from '../../../../interfaces/storeInterfaces';
import {
  SectionContent,
  SectionTitle,
  Separator,
  SpanDisplayFlexInline,
} from '../../../../styles/commonAccountDetails.style';
import * as S from './infoSection.style';

const InfoSection: React.FC = () => {
  const dispatch = useDispatch();
  const renewal = useRouteMatch('/contracts/:id/renewal') || false;
  const interim = useRouteMatch('/contracts/:id/interim') || false;
  const edit = useRouteMatch('/contracts/:id/edit') || false;
  const view = useRouteMatch('/contracts/:id/view') || false;
  const newContract = useRouteMatch('/contracts/new') || false;

  const contractInfo = useSelector((state: StoreInterface) => state.contractsPage.contractInfo);
  const contractInfoValidation = useSelector(
    (state: StoreInterface) => state.contractsPage.contractInfoValidation
  );

  const {
    comments,
    contactName,
    credit,
    customerEmail,
    customerName,
    creditConsumed,
    overUsage,
    endDate,
    startDate,
    invoiceNumber,
    onDemandChargeStart,
    redislabsEmailList,
    remainingCredit,
  } = contractInfo;

  const totalCredit = (Number(credit) * 1000 + Number(remainingCredit) * 1000) / 1000;

  const [showStartDatePicker, setShowStartDatePicker] = useState(false);
  const [currentStartMonth, setCurrentStartMonth] = useState<Moment>(utcDateStartOfDay);

  const [showEndDatePicker, setShowEndDatePicker] = useState(false);
  const [currentEndMonth, setCurrentEndMonth] = useState<Moment>(endDate || utcDateStartOfDay);

  const initialEndDate: Moment | null = useMemo(() => {
    return currentEndMonth;
  }, []);

  useEffect(() => {
    if (interim) {
      dispatch({
        type: 'contractInfo',
        payload: { startDate: utcDateStartOfDay },
      });
    }
  }, []);

  useEffect(() => {
    if (renewal) {
      const renewalStartDate = moment(endDate).clone().add(1, 'day');
      dispatch({
        type: 'contractInfo',
        payload: { startDate: renewalStartDate, endDate: null },
      });
    }
  }, []);


  const updateStateOnInputTypeWrapper = (event) =>
    updateStateOnInputType({
      eTarget: event.target,
      action: 'contractInfo',
      dispatch,
      callback: () =>
        dispatch({
          type: 'setFormTouchedState',
          payload: true,
        }),
    });

  return (
    <S.InfoSection>
      <SectionTitle>Info</SectionTitle>
      <SectionContent>
        <div>
          <SpanDisplayFlexInline view={view}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="customerName">
              {`Customer name${edit || newContract ? ' *' : ''}`}
              {view ? (
                <CS.TooltipOverflowHidden>
                  <CS.TooltipStyleOverride
                    tooltipContent={
                      <CS.TooltipTextWithTooltip>{customerName}</CS.TooltipTextWithTooltip>
                    }
                    placement="top"
                    trigger="hover"
                    textColor={theme.semantic.color.text.neutral700}
                  >
                    <CS.TooltipViewValues>{customerName}</CS.TooltipViewValues>
                  </CS.TooltipStyleOverride>
                </CS.TooltipOverflowHidden>
              ) : (
                <Input
                  autoComplete="new-customer-name"
                  id="customerName"
                  name="customerName"
                  data-testid="textinput--customer-name"
                  type="text"
                  value={customerName}
                  placeholder="Enter name"
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                  width="100%"
                  disabled={view}
                />
              )}
            </label>

            {view && (
              <label htmlFor="onDemandChargeStart">
                Estimated credit end
                <CS.ViewValues>
                  {onDemandChargeStart && moment.utc(onDemandChargeStart).format('DD/MM/YYYY')}
                </CS.ViewValues>
              </label>
            )}
          </SpanDisplayFlexInline>

          <SpanDisplayFlexInline view={view}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="contactName">
              {`Contact name${edit || newContract ? ' *' : ''}`}
              {view ? (
                <CS.TooltipOverflowHidden>
                  <CS.TooltipStyleOverride
                    tooltipContent={
                      <CS.TooltipTextWithTooltip>{contactName}</CS.TooltipTextWithTooltip>
                    }
                    placement="top"
                    trigger="hover"
                    textColor={theme.semantic.color.text.neutral700}
                  >
                    <CS.TooltipViewValues>{contactName}</CS.TooltipViewValues>
                  </CS.TooltipStyleOverride>
                </CS.TooltipOverflowHidden>
              ) : (
                <Input
                  autoComplete="new-contactName"
                  id="contactName"
                  name="contactName"
                  data-testid="textinput--contact-name"
                  type="text"
                  value={contactName}
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                  width="100%"
                  disabled={view}
                />
              )}
            </label>

            <label htmlFor="customerEmail">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              {`Contact email${edit || newContract ? ' *' : ''}`}
              {view ? (
                <CS.ViewValues>{customerEmail}</CS.ViewValues>
              ) : (
                <Input
                  autoComplete="new-contact-email"
                  id="customerEmail"
                  name="customerEmail"
                  data-testid="textinput--contact-email"
                  type="email"
                  value={customerEmail}
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                  error={
                    (!contractInfoValidation.customerEmail &&
                      errorsMessages.customerEmail) as string
                  }
                  onBlur={({ target }) => {
                    dispatch({
                      type: 'contractInfoValidation',
                      payload: { customerEmail: target.validity.valid },
                    });
                  }}
                  width="100%"
                  disabled={view}
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                />
              )}
            </label>
          </SpanDisplayFlexInline>

          <SpanDisplayFlexInline view={view}>
            <label htmlFor="redislabsEmailList">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <span>{`RedisLabs email list${edit || newContract ? ' *' : ''}`}</span>
              {view ? (
                <CS.TooltipOverflowHidden>
                  <CS.TooltipStyleOverride
                    tooltipContent={
                      <CS.TooltipTextWithTooltip>{redislabsEmailList}</CS.TooltipTextWithTooltip>
                    }
                    placement="top"
                    trigger="hover"
                    textColor={theme.semantic.color.text.neutral700}
                  >
                    <CS.TooltipViewValues>{redislabsEmailList}</CS.TooltipViewValues>
                  </CS.TooltipStyleOverride>
                </CS.TooltipOverflowHidden>
              ) : (
                <Input
                  autoComplete="new-contact-redislabsEmailList"
                  id="redislabsEmailList"
                  name="redislabsEmailList"
                  data-testid="textinput--contact-redislabsEmailList"
                  type="email"
                  value={redislabsEmailList}
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                  error={
                    (!contractInfoValidation.redislabsEmailList &&
                      errorsMessages.redislabsEmailList) as string
                  }
                  onBlur={({ target }) => {
                    dispatch({
                      type: 'contractInfoValidation',
                      payload: { redislabsEmailList: target.validity.valid },
                    });
                  }}
                  width="100%"
                  disabled={view}
                  multiple
                  pattern="([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$)*(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*,\s*|\s*$))*"
                />
              )}
            </label>
          </SpanDisplayFlexInline>

          <SpanDisplayFlexInline>
            <label htmlFor="startDate">
              <span>{`Start Date (UTC)${newContract ? ' *' : ''}`}</span>
              {edit || view || renewal ? (
                <CS.ViewValues>
                  {startDate?.format('DD/MM/YYYY')}
                </CS.ViewValues>
              ) : (
                <CS.SpanWithDate>
                  <span>{startDate?.format('DD/MM/YYYY')}</span>
                  <CS.SpanWithDateButtonsWrapper>
                    {startDate ? (
                      <Button
                        data-testid="button--clear-start-date"
                        variant="secondary"
                        size="small"
                        onClick={() => {
                          dispatch({
                            type: 'clearIndividualContractInfoDate',
                            payload: { startDate: null },
                          });

                          // used to set the form's touch state
                          dispatch({
                            type: 'setFormTouchedState',
                            payload: true,
                          });
                        }}
//                        disabled={renewal}
                      >
                        <CancelIcon size="L" />
                      </Button>
                    ) : (
                      <Button
                        variant="secondary"
                        size="small"
                        data-testid="button--start-date"
                        onClick={() => {
                          setShowStartDatePicker(!showStartDatePicker);

                          // used to set the form's touch state
                          dispatch({
                            type: 'setFormTouchedState',
                            payload: true,
                          });
                        }}
                      >
                        <CalendarIcon size="L" />
                      </Button>
                    )}
                  </CS.SpanWithDateButtonsWrapper>
                  {showStartDatePicker && (
                    <DatePicker
                      disabledDays={{
                        before: utcDateStartOfDay?.toDate(),
                        after: (interim && !endDate?.isBefore(initialEndDate))
                          ? (initialEndDate as Moment)?.toDate()
                          : (endDate as Moment)?.toDate()
                      }}
                      showOnClickOutsideFn={() => {
                        setShowStartDatePicker(false);
                      }}
                      currentMonth={currentStartMonth.toDate()}
                      setCurrentMonth={(newMonth, keepPickerOpen) => {
                        setCurrentStartMonth(moment(newMonth));
                        setShowStartDatePicker(keepPickerOpen);
                      }}
                      currentDay={startDate?.toDate()}
                      setCurrentDay={(newDate, keepPickerOpen) => {
                        dispatch({
                          type: 'contractInfo',
                          payload: { startDate: moment(newDate as Date) },
                        });
                        setShowStartDatePicker(keepPickerOpen);
                      }}
                    />
                  )}
                </CS.SpanWithDate>
              )}
            </label>

            <label htmlFor="endDate">
              <S.SpanWithGrace>
                {`End Date (UTC)${newContract ? ' *' : ''}`}
                {contractInfo.gracePeriod && (
                  <S.GracePeriodWrapper data-testid="span--grace-indication">
                    <InfoIcon size="L" color="notice500" />
                    <span>Grace Period</span>
                  </S.GracePeriodWrapper>
                )}
              </S.SpanWithGrace>
              {edit || view ? (
                <CS.ViewValues>{endDate?.format('DD/MM/YYYY')}</CS.ViewValues>
              ) : (
                <CS.SpanWithDate>
                  <span>{endDate?.format('DD/MM/YYYY')}</span>
                  <CS.SpanWithDateButtonsWrapper>
                    {endDate ? (
                      <Button
                        data-testid="button--clear-start-date"
                        variant="secondary"
                        size="small"
                        onClick={() => {
                          dispatch({
                            type: 'clearIndividualContractInfoDate',
                            payload: { endDate: null },
                          });

                          // used to set the form's touch state
                          dispatch({
                            type: 'setFormTouchedState',
                            payload: true,
                          });
                        }}
                      >
                        <CancelIcon size="L" />
                      </Button>
                    ) : (
                      <Button
                        variant="secondary"
                        size="small"
                        data-testid="button--end-date"
                        onClick={() => {
                          setShowEndDatePicker(!showEndDatePicker);

                          // used to set the form's touch state
                          dispatch({
                            type: 'setFormTouchedState',
                            payload: true,
                          });
                        }}
                      >
                        <CalendarIcon size="L" />
                      </Button>
                    )}
                  </CS.SpanWithDateButtonsWrapper>
                  {showEndDatePicker && (
                    <DatePicker
                      disabledDays={{
                        before: (startDate as Moment)?.toDate() || utcDateStartOfDay?.toDate()
                      }}
                      showOnClickOutsideFn={() => {
                        setShowEndDatePicker(false);
                      }}
                      currentMonth={currentEndMonth.toDate()}
                      setCurrentMonth={(newMonth, keepPickerOpen) => {
                        setCurrentEndMonth(moment(newMonth));
                        setShowEndDatePicker(keepPickerOpen);
                      }}
                      currentDay={endDate?.toDate()}
                      setCurrentDay={(newDate, keepPickerOpen) => {
                        dispatch({
                          type: 'contractInfo',
                          payload: {
                            endDate: moment(newDate as Date),
                          },
                        });
                        setShowEndDatePicker(keepPickerOpen);
                      }}
                    />
                  )}
                </CS.SpanWithDate>
              )}
            </label>
          </SpanDisplayFlexInline>
        </div>
        <Separator />
        <div>
          <SpanDisplayFlexInline>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="credit">
              <span>{`Credit amount${newContract ? ' *' : ''}`}</span>
              {view || edit ? (
                <CS.ViewValues>{`$ ${credit || 0}`}</CS.ViewValues>
              ) : (
                <Input
                  autoComplete="new-credit-amount"
                  min={0}
                  max={100000000.0}
                  step={0.001}
                  id="credit"
                  name="credit"
                  data-testid="textinput--credit-amount"
                  type="number"
                  value={credit}
                  placeholder="$"
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                  error={(!contractInfoValidation.credit && errorsMessages.credit) as string}
                  onBlur={({ target }) => {
                    dispatch({
                      type: 'contractInfoValidation',
                      payload: { credit: target.validity.valid },
                    });
                  }}
                  width="100%"
                  disabled={edit || view}
                />
              )}
            </label>

            <label htmlFor="creditConsumed">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              Credit consumed
              <CS.ViewValues>{`$ ${creditConsumed || 0}`}</CS.ViewValues>
            </label>

            <label htmlFor="overUsage">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              Over usage
              <CS.ViewValues>{`$ ${overUsage || 0}`}</CS.ViewValues>
            </label>
          </SpanDisplayFlexInline>

          <SpanDisplayFlexInline>
            {interim && (
              <label htmlFor="totalCredit">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                Credit amount including leftovers
                <CS.ViewValues>{`$ ${totalCredit}`}</CS.ViewValues>
              </label>
            )}
            <label htmlFor="invoiceNumber">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              NS invoice
              {view ? (
                <CS.ViewValues>
                  <span>{invoiceNumber}</span>
                </CS.ViewValues>
              ) : (
                <Input
                  autoComplete="new-invoice-number"
                  id="invoiceNumber"
                  name="invoiceNumber"
                  data-testid="textinput--invoice-number"
                  type="text"
                  value={invoiceNumber}
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                  width="100%"
                  disabled={view}
                />
              )}
            </label>
          </SpanDisplayFlexInline>

          <SpanDisplayFlexInline>
            <label htmlFor="comments">
              Comments
              <CS.Textarea
                name="comments"
                id="comments"
                data-testid="textarea--comments"
                rows={6}
                disabled={view}
                value={comments || ''} // protection for null values
                onChange={(event) => updateStateOnInputTypeWrapper(event)}
              />
            </label>
          </SpanDisplayFlexInline>
        </div>
      </SectionContent>
    </S.InfoSection>
  );
};

export default InfoSection;
